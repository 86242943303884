import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { getMsalInstance, getConfig } from "../index";

const azureAdAxiosInstance = axios.create({
    validateStatus: function (status) {
        if (status === 403)
            alert("Sorry your account is not Authorised to do that.");
        return status >= 200 && status < 300;
    }
});
azureAdAxiosInstance.CancelToken = axios.CancelToken;

const refreshToken = async (failedRequest) => {
    let msalInstance = await getMsalInstance(azureAdAxiosInstance.setAccount);
    let config = await getConfig();
    const account = msalInstance.getActiveAccount();
    if (!account) {
        return Promise.reject(Error("No active account! Verify a user has been signed in and setActiveAccount has been called."));
    }
    const response = await msalInstance.acquireTokenSilent({
        scopes: config.apiScopes,
        account: account
    });
    //console.log("msal response", response);
    azureAdAxiosInstance.defaults.headers.common = { 'Authorization': `bearer ${response.accessToken}` };
    failedRequest.response.config.headers['Authorization'] = `bearer ${response.accessToken}`;
    //console.log("HO token refreshed and set");
    return Promise.resolve();
};

// This should be called when we know we need oauth (when terminal settings are retrieved and we find we're BOOTH (CS))
azureAdAxiosInstance.createRefreshInterceptor = () => {
    // set flag so that we use refresh interceptor for all instances
    window.useAuthRefresh = true;
    createAuthRefreshInterceptor(azureAdAxiosInstance, refreshToken);
}
if (window.useAuthRefresh)
    createAuthRefreshInterceptor(azureAdAxiosInstance, refreshToken);

export default azureAdAxiosInstance;
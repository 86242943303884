import React, { Fragment } from "react";
import { Header } from 'semantic-ui-react';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null
        };
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState(
            {
                hasError: true,
                error: error,
                errorInfo: info
            }
        );
    }

    render() {
        if (this.state.hasError) {
            return (<Fragment>
                <Header as="h1">Oops :(</Header>
                <div>Something went wrong</div>
                <details style={{ whiteSpace: 'pre-wrap' }}>
                    {this.state.error && this.state.error.toString()}
                    <br />
                    {this.state.errorInfo.componentStack}
                </details>
            </Fragment>
            );
        }

        return this.props.children;
    }
}
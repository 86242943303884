export const ClientApis = {
    ClientApiUrl: "http://127.0.0.1:5050/api/v1",
    GetSettingsForTerminal: "/Terminals/GetSettingsForTerminal/",
    Login: "/Administration/Login",
    GetStatus: "/Service/GetStatus",
    GetTransactions: "/Transactions/GetByTranDate",
    ReprintTicket: "/Orders/ReprintTicket",
    RefundOrder: "/Orders/RefundOrder",
    NotInService: "/Terminals/NotInService",
    ResumeService: "/Terminals/ResumeService",
    PrintTickets: "/Orders/Print",
    PrintOrder: "/Orders/PrintOrder",
    LogoutUser: "/Administration/Logout",
    SearchTickets: "/Transactions/Search/",
    GetOrderDetails: "/Transactions/GetById/",
    RefundTicket: "/Orders/RefundTicket",
    CancelTicket: "/Orders/CancelTicket",
    CancelSale: "/Orders/CancelSale",
    RefundManual: "/Orders/RefundManual",
    RefundSale: "/Orders/RefundSale",
    RefundBooth: "/Orders/RefundBooth",
    PrintSpecificTicket: "/Orders/PrintTicket",
    ManualPayment: "/Orders/ProcessManualOrder",
    SendTicketEmail:"/Orders/SendTicketEmail",
    ProcessOrder: "/Orders/ProcessOrder",
    GetOrderPdf: "/Orders/GetOrderPdf/",
    ManualRefundSale: "/Orders/ManualRefundSale", 
    ManualRefundTicket: "/Orders/ManualRefundTicket",  
    CashDrawerChange: "/Administration/CashDrawerChange",
    GetLastUserLogin: "/Administration/GetLastUserLogin",
    GetShiftSummary: "/Administration/GetShiftSummary/",
    LockScreen: "/Administration/LockScreen/",
    OpenCashDrawer: "/Administration/OpenCashDrawer",
    CreateCashAdjustment: "/Administration/CreateCashAdjustment",
    ValidateLogin: "/Administration/ValidateLogin",
}

export const StatusCodes = {
    Ok: 0,
    Refresh: 1,
    NotInService: 2,
    HeadOfficeOffline: 3
}

export const KioskSequences = {
    NotSet: 0,
    Journeys: 1,
    ServiceMessage: 2,
    HoursOfOperation: 3,
    Tickets: 4,
    Admin: 5
}

export const BoothSequences = {
    Home: 0,
    SearchPopup: 1,
    Search: 2,
    SalesInformation: 3,
    Logout: 4,
    ScreenLock: 5,
    Login: 6,
    CancelSale: 7,
    RefundSale: 8,
    RefundTicket: 9,
    CancelTicket: 10,
    ManualRefundSale: 11,
    ManualRefundTicket: 12,
    EftPayment: 15,
    PdfEmailDownload: 16,
    SplitPayment: 17,
    ManualPayment: 18,
    PaymentProcessor: 19,
    OrderError: 20,
    IssueTicket: 21,
    CashDrawerChange: 22,
    CashDrawerChangeResult: 23,
    ShiftSummary: 24,
    LogoutConfirmation: 25,
    CashAdjustment: 26,
}

export const BarcodePrefix = "SEARCH:"
export const BarcodeTimeout = 1000

export const NoticeTypes = {
    Acknowledge: 1,
    Answer: 2
}

export const ActionItems = {
    Idle: 0,
    Reprint: 1,
    CancelSale: 2,
    RefundSale: 3,
    RefundTicket: 4,
    CancelTicket: 5,
    ManualRefundSale: 6,
    ManualRefundTicket: 7,
}

export const PaymentMethods = {
    // Unknown: 0,
    // Cash: 16,
    // Debit: 1,
    // Bankcard: 2,
    Mastercard: 3,
    Visa: 4,
    American_Express: 5,
    Diner_Club: 6,
    JCB: 7,
    // LabelCard: 8,
    // Other: 12,
    Wechat: 13,
    AliPay: 14,
    PayPal: 15,
}

export const monthOptions = () => {
    var monthList = ["",
        'January', 'February', 'March', 'April',
        'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December'
    ];
    return monthList.map((m,i) => {
        var value = '00' + i
        value = value.substr(value.length-2,2)
        return {key:i, text:m, value:value}
    })
}

export const ManualTransactionMethods = {
    Cash: 1, 
    CardElectronic: 2, 
    Voucher: 3,
    BankTransfer: 4, 
    Other: 5
}

export const BoothTransactionMethods = {
    CardCash: 1,  
    Voucher: 2,
}
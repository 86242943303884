// use to generate payment reference suffix per terminal
// see https://zelark.github.io/nano-id-cc/ for chance of collision PER TERMINAL assuming terminal Id is unique
const { customAlphabet } = require('nanoid');
const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const nanoid = customAlphabet(alphabet, 9);

// rules for sorting(grouping) fares - should be by the 4th and 5th character of product ref
const fareTypeSortOrders = (fareType) => {
    switch (fareType) {
        case 'A': return 1;
        case 'C': return 2;
        case '1': return 3;
        case '2': return 4;
        case 'P': return 5;
        case 'S': return 6;
        case 'G': return 7;
        case 'H': return 8;
        case 'U': return 9;
        case 'J': return 10;
        case 'K': return 11;
        case 'L': return 12;
        case 'M': return 13;
        default: return 14;
    }
};
const journeySortOrders = (journeyType) => {
    switch (journeyType) {
        case 'S': return 1;
        case 'R': return 2;
        case 'T': return 3;
        case '2': return 4;
        case '4': return 5;
        default: return 6;
    }
};
const getFareSortOrder = (fare) => {
    let fareType = fare?.productRef?.substring(3, 4);
    let journeyType = fare?.productRef?.substring(4, 5);
    let sortOrder = (10 * fareTypeSortOrders(fareType)) + journeySortOrders(journeyType);
    return sortOrder;
};

const Utils = {
    getCurrentYearShort: () => {
        var year = Utils.getCurrentYear();
        return year - 2000;

    },
    getCurrentYear: () => {
        var date = new Date();
        return date.getFullYear()
    },
    getCurrentMonth: () => {
        var date = new Date();
        return date.getMonth() + 1
    },
    getSearchDateString: (dt) => {
        var date = dt.toDate ? dt.toDate() : new Date(dt);
        return date.getFullYear() + "-" + Utils.getPaddedTime(date.getMonth() + 1) + "-" + Utils.getPaddedTime(date.getDate());
    },
    getPaddedTime: (n) => {
        return n > 9 ? '' + n : '0' + n
    },
    getDisplayDateString: (dt) => {
        var date = new Date(dt);
        return Utils.getPaddedTime(date.getDate()) + "/" + Utils.getPaddedTime(date.getMonth() + 1) + "/"
            + Utils.getPaddedTime(date.getFullYear())

    },
    getDisplayDateTimeString: (dt) => {
        var date = new Date(dt);
        return Utils.getDisplayDateString(dt) + " " + Utils.getDisplay12HourTime(date)

    },
    getDisplay12HourTime(dt) {
        var hours = dt.getHours();
        var ampm = "am"
        if (hours > 11) {
            ampm = "pm"
        }
        if (hours > 12) {
            hours = hours - 12
        }
        return hours + ":" + Utils.getPaddedTime(dt.getMinutes()) + " " + ampm
    },
    getDisplayTimeString: (dt) => {
        return dt.getHours() + ":" + dt.getMinutes();
    },
    getDisplayLongTimeString: (dt) => {
        return dt.getHours() + ":" + Utils.getPaddedTime(dt.getMinutes()) + ":" + Utils.getPaddedTime(dt.getSeconds()) + ":" + Utils.getPaddedTime(dt.getMilliseconds());
    },
    sortFares: (f1, f2) => {
        let s1 = getFareSortOrder(f1);
        let s2 = getFareSortOrder(f2);
        return s1 - s2;
    },
    getCurrentDateTimeString: () => {
        var now = new Date();
        var result = now.getFullYear()
            + Utils.getPaddedTime(now.getMonth() + 1)
            + Utils.getPaddedTime(now.getDate())
            + Utils.getPaddedTime(now.getHours())
            + Utils.getPaddedTime(now.getMinutes())
            + Utils.getPaddedTime(now.getSeconds())
            + now.getMilliseconds()
        return result;
    },
    formatCurrency: (value, locale) => {
        var result = new Intl.NumberFormat(locale.localeCode, { style: 'currency', currency: locale.currencyCode }).format(value);
        return result;
    },
    formatCurrencyNumber: (value, locale) => {
        var result = new Intl.NumberFormat(locale.localeCode, { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(value);
        return result;
    },

    todayIsWeekday: () => {
        var now = new Date();
        if (now.getDay() === 0 || now.getDay() === 6) {
            return false;
        }

        return true;
    },
    isBeforeTime: (tstr) => {
        var now = Utils.getTimeNow(),
            tobj = new Date(),
            parts = tstr.split(':');

        tobj.setHours(parts[0], parts[1]);

        return (now < tobj) ? true : false;
    },
    getTimeNow: () => {
        return new Date();
    },
    isAfterTime: (tstr) => {
        var now = Utils.getTimeNow(),
            tobj = new Date(),
            parts = tstr.split(':');

        tobj.setHours(parts[0], parts[1]);

        return (now > tobj) ? true : false;
    },
    getScheduleInfo: (scheduleInfos, stopScheduleInfos) => {
        var scheduleInfo;
        if (stopScheduleInfos) {
            scheduleInfo = stopScheduleInfos.find((si) => {
                return Utils.isAfterTime(si.warningFrom) && Utils.isBeforeTime(si.warningTo)
            });
        }
        if (!scheduleInfo && scheduleInfos) {
            scheduleInfo = scheduleInfos.find((si) => {
                return Utils.isAfterTime(si.warningFrom) && Utils.isBeforeTime(si.warningTo)
            });
        }

        return scheduleInfo;
    },
    showScheduleInfo: (scheduleInfos) => {
        return scheduleInfos && scheduleInfos.map((si) => {
            return (Utils.isAfterTime(si.warningFrom) || Utils.isBeforeTime(si.warningTo))
        })
        //return scheduleInfos && (Utils.isAfterTime(scheduleInfos.warningFrom) || Utils.isBeforeTime(scheduleInfos.warningTo));
    },
    getJourneyType: (prefix, ref) => {
        var type = "";
        //assuming AA-BB with optional -C on the end
        var parts = ref.split('-');
        if (parts[0] === prefix) {
            type = parts[1].charAt(1) //shoudl be 'S' or 'R'
        }
        return type
    },
    getJourneyTypeDesc: (prefix, ref) => {
        let jt = Utils.getJourneyType(prefix, ref);
        if (jt === "R")
            return "Return";
        return "Single";
    },
    getDiscountType: (prefix, ref) => {
        var type = "";
        //assuming AA-BB with optional -C on the end
        var parts = ref.split('-');
        
        if (parts[0] === prefix && parts.length === 3) {
            type = parts[2] //should be 'P' or 'S' or ???
        }
        return type
    },
    getFareType: (prefix, ref) => {
        var fareType = "";
        //assuming AA-BB with optional -C on the end
        var parts = ref.split('-');
        fareType = parts[1].charAt(0)

        return fareType
    },
    createProductRef: (prefix, fare) => {
        var productRef = prefix + "-"
            + fare.fareType
            + ((fare.journeyType === "single" || fare.journeyType === "Single") ? "S" : "R")
        if (fare.discountType) {
            productRef += ((fare.discountType === "") ? "" : "-" + fare.discountType)
        }
        return productRef
    },
    createPaymentReference: (terminalData) => {
        //console.log("createPaymentReference->terminalData:", terminalData);
        return terminalData.terminalType.substring(0, 1) + terminalData.terminalId + '-' + nanoid();
    },
    validateNumericInput: (value) => {
        function localStringToNumber(s) {
            return Number(String(s).replace(/[^0-9.-]+/g, ""))
        }
        var error = false;

        if (value === "") {
            error = true;
        }
        var numericValue = value ? localStringToNumber(value) : 0;
        if (numericValue < 0) {
            error = true;
        }

        return error
    },



    printTicketEventListener: (action) => {
        console.log("ptEventListener");
        const printTicketEvent = (event) => {
            //console.log("event", event);
            if (event.data === "iframe-print-complete") {
                let iframe = document.getElementById("ticketframe");
                if (iframe) {
                    document.body.removeChild(iframe);
                }
                window.removeEventListener('message', printTicketEvent);
                if (action) action();
            }
        }
        window.addEventListener('message', printTicketEvent)
    },
    printTicketAddIframe: (url) => {
        let iframe = document.createElement('iframe');
        //note that the iframes page has to show to allow the window.onload event to fire 
        //if the window.onload event doesn't fire, the post print shutdown won't occur
        iframe.style.height = "1px";
        iframe.style.width = "1px";
        iframe.id = 'ticketframe';
        iframe.src = url;
        document.body.appendChild(iframe);
    },

    IsCCExpiryValid: (month, year) => {
        var date = new Date();
        if (year < 2000) year = year + 2000
        var mth = parseInt(month) - 1
        var expiryDate = new Date(year, mth, 1)
        console.log(expiryDate)
        return expiryDate > date;
    },

    addMonthsToNow: (months) => {
        var date = new Date();
        date.setMonth(date.getMonth() + months);
        return date;
    },

    formatDollarValue: (amount) => {
        if (isNaN(amount) || amount === "" || amount === null || amount < 0) {
            return ""
        }
        if (parseFloat(amount).toFixed(2) != amount) {
            return parseFloat(amount).toFixed(2);
        }
        if (parseFloat(amount).toFixed(2) == parseInt(amount)) {
            return amount;
        }
        if (amount > 0) {
            return parseFloat(amount).toFixed(2);
        }
        return "";
    }
}
export default Utils


// import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import axios from 'axios';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import Logger from './components/Logger'
import { PublicClientApplication, EventType } from "@azure/msal-browser";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

// global config
export const getConfig = async () => {
  try {
      if (window.config) return window.config;       
      var res = await axios.get('/config');
      Logger.Debug("Got config: ", res.data, res );
      window.config = res.data;
      return window.config;  
  }
  catch (e) {
      Logger.Error("getConfig failed: ", e);
      return null;
  }
};

export const getMsalInstance = async (setAccount = null) => {
  if (window.msalInstance) return window.msalInstance;
  //console.log("Building MSAL instance...");
  let config = await getConfig();
  Logger.Debug("Got config for msal", config);
  window.msalInstance = new PublicClientApplication({ auth: config.auth });
  let accounts = window.msalInstance.getAllAccounts();
  if (accounts.length > 0) {
      window.msalInstance.setActiveAccount(accounts[0]);
  }
  window.msalInstance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
          const account = event.payload.account;
          window.msalInstance.setActiveAccount(account);
          //console.log('Account', account);
          if(setAccount)
              setAccount(account);
      }
  });
  return window.msalInstance;
};

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


import React, { Component, Fragment, Suspense } from 'react';
import ErrorBoundary from './components/ErrorBoundary';
import axios from "./utils/AzureAdAxiosClient";
import { Route } from 'react-router';
import { Container } from 'semantic-ui-react';
import Logger from './components/Logger';
import { ClientApis } from './config';
import { getMsalInstance, getConfig } from './index';
import Utils from './components/Utils';

// MSAL imports
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            terminalData: {},
            systemfail: false,
            scopes: null
        };
    }
    static displayName = App.name;

    async getTerminalSettings() {
        var data = await this.getTerminalData();
        Logger.Debug('getTerminalSettings->data', data);
        if (!data || JSON.stringify(data) === "{}") {
            Logger.Error("Terminal settings is empty, please confirm POS can contact HO");
            this.setState({ systemfail: true });
        }
        else if (data.terminalId === "SETUP" || data.TerminalId === "SETUP") {
            this.setState({ systemfail: false, terminalData: data });
        }
        else {
            if (this.state.terminalData.terminalId === "SETUP" || this.state.terminalData.TerminalId === "SETUP" ) {
                window.location.reload();
            }
            else {
                this.setState({ systemfail: false, terminalData: data });
                await this.checkLastTransStatusOnStartup();
            }
        }
        // TODO: If at some point we need to secure Customer service API, call axios.createRefreshInterceptor here
    }
    async checkLastTransStatusOnStartup() {
        var url = ClientApis.ClientApiUrl + "/Orders/CheckLastTransactionStatus";
        await axios.get(url)
            .then(res => {
                if (res.statusText !== "OK") {
                    Logger.Error("checkLastTransStatusOnStartup not OK: ", res);
                    this.setState({ systemfail: true });
                }
                else {
                    // check the last status, and if needed print
                    if (res.data.lastRef) {
                        Logger.Info("checkLastTransStatusOnStartup result: ", res.data);
                        Utils.printTicketEventListener();
                        Utils.printTicketAddIframe(ClientApis.ClientApiUrl + "./Orders/PrintTransStatus/"
                            + res.data.lastRef + "/" + encodeURIComponent(res.data.lastStatus) + "/" + encodeURIComponent(res.data.lastAmount)
                            + "/" + encodeURIComponent(res.data.transDate));
                    }
                }
            })
            .catch(async e => {
                Logger.Error("checkLastTransStatusOnStartup failed: ", e);
                //this.setState({systemfail: true});
            });
    }
    async getTerminalData() {
        var url = ClientApis.ClientApiUrl + ClientApis.GetSettingsForTerminal
        var data = {};
        await axios.get(url)
            .then(res => {
                data = res.data;
                //console.log("terminal data==>", data)
            })
            .catch(async e => {
                //console.log("terminal error==>", e)
                if (e.message.includes("No active account!")) {
                    let msalInstance = await getMsalInstance();
                    let config = await getConfig();
                    Logger.Info("getTerminalData->catch: ", msalInstance, config.apiScopes);
                    this.setState({ msalInstance: msalInstance, scopes: config.apiScopes });
                }
                else {
                    Logger.Error("GetTerminalSettings failed: ", e);
                    this.setState({ systemfail: true });
                }
            });

        return data;
    }

    async componentDidMount() {
        axios.setAccount = () => {
            this.setState({ msalInstance: null })
            this.getTerminalSettings();
        };
        await this.getTerminalSettings();
    }

    getHealthReport() {
        return ('{ "health":"good"}')
    }
    render() {

        var terminalData = this.state.terminalData;
        var terminalType = terminalData.terminalType ? terminalData.terminalType : "";
        var Terminal = (<div id="main"><div className="inner">Loading...</div></div>);
        var offline = null;

        if (this.state.systemfail || (terminalData.terminalId == "SETUP" || terminalData.TerminalId == "SETUP")) {
            let OfflineComponent = React.lazy(() => import('./components/Offline'));
            offline = <OfflineComponent terminalData={terminalData} checkStatus={async () => { await this.getTerminalSettings(); }} />;
        }
        else if (terminalType === "KIOSK") {
            Terminal = React.lazy(() => import('./Kiosk/Kiosk'));
        }
        else if (terminalType === "VEHICLE") {
            Terminal = React.lazy(() => import('./Onboard/Onboard'));
        }
        else if (terminalType === "BOOTH") {
            if (terminalData.isCustomerServiceView === true)
                Terminal = React.lazy(() => import('./CustomerService/CustomerService'));
            else
                Terminal = React.lazy(() => import('./TicketBooth/TicketBooth'));
        }        
        //console.log("render->terminalData,terminalType,this.state.msalInstance,this.state.scopes: ", terminalData, terminalType, this.state.msalInstance, this.state.scopes);
        return (
            <Suspense fallback={<div>Loading...</div>}>
                <Container fluid>
                    {offline ? offline :
                        <Fragment>
                            <ErrorBoundary>
                                {this.state.msalInstance && this.state.scopes &&
                                    <MsalProvider instance={this.state.msalInstance}>
                                        <MsalAuthenticationTemplate
                                            interactionType={InteractionType.Redirect}
                                            authenticationRequest={{ scopes: this.state.scopes }}
                                            errorComponent={() => <p>Auth Error</p>}
                                            loadingComponent={() => <p>Authenticating (Loading)...</p>}>
                                            {this.state.account ? <p>Authenticated</p> : <p>Authenticating...</p>}
                                        </MsalAuthenticationTemplate>
                                    </MsalProvider>
                                }
                                {terminalType !== "" ?
                                    <Route exact path='/' render={() => {
                                        //console.log("Route->render Terminal", terminalData);
                                        return (<Terminal terminalData={terminalData} sequence={1} reloadConfig={() => this.getTerminalSettings()} />);
                                    }
                                    } />
                                    : null}
                            </ErrorBoundary>
                            <Route exact path="/health" render={() => this.getHealthReport()} />
                        </Fragment>
                    }
                </Container>
            </Suspense>
        );
    }
}

export default App